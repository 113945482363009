<template>
	<div class="matchmaking-screen">
		<div class="main-column">
			<div class="login">
				<div class="logo">
					<img src="@/assets/logo.png"/>
				</div>
			
				<div class="new-match">
					<h3>Crea nuovo match</h3>
					
					<div class="button arrow" @click="startMatch">Crea</div>
				</div>
				
				<div class="join-match">
					<h3>Unisciti ad un match</h3>
					
					<div class="inputs">
						<input v-model="join_code" placeholder="Codice" type="text"/>
						<input v-model="join_name" placeholder="Nome" type="text"/>
					</div>
					
					<div class="button arrow" @click="joinMatch">Unisciti</div>
				</div>
			</div>
		</div>
		
		<transition name="fade">
			<div class="loading" v-if="loading"></div>
		</transition>
	</div>
</template>

<script>
	import { helperMixin } from '@/store/helper.js';
	import axios from 'axios';
	
	export default {
		name: 'Matchmaking',
		mixins : [helperMixin],
		data() {
			return {
				loading : false,
				join_code : '',	
				join_name : ''
			};
		},
		computed: {
		
		},
		methods : {
			startMatch(){
				this.loading = true;
				axios.post(this.api_url+'start')
					.then(function (response) {
						if(response.status == 200 && response.data.success){
							this.$store.commit('startMatch', response.data.message);
							this.$router.replace({ path: '/lobby' })
						}
						this.loading = false;
					}.bind(this));
			},
			joinMatch(){
				this.loading = true;
				axios.post(this.api_url+'join', { code: this.join_code, name: this.join_name})
					.then(function (response) {
						if(response.status == 200 && response.data.success){
							this.$store.commit('joinMatch', response.data.message);
							this.$store.commit('setName', this.join_name);
							this.$router.replace({ path: '/lobby' })
						}
						this.loading = false;
					}.bind(this));
			}
		},
		components: {
			
		}
	}
</script>

<style lang="scss">
	@import "../assets/styles/variables.scss";
	
	.matchmaking-screen{
		position: relative;
		width: 100%;
		min-height: 100vh;
		background: $red;
		display: flex;
		align-items: center;
	
		.main-column{
			height: 100%;
		}
		
		.login{	
			background: $white;
			border-radius: 35px;
			padding: 50px 25px;
			margin: 25px 0;
			
			h3{
				text-transform: uppercase;
				font-size: 16px;
				font-weight: normal;
				margin-bottom: 10px;
				margin-left: 25px;
			}

			.logo{
				position: absolute;
				bottom: 100%;
				padding-left: 25px;
				padding-bottom: 50px;
				
				img{
					width: 200px;
				}
			}			
			
			.join-match{
				margin-top: 50px;
				
				input{
					width: 100%;
					margin-bottom: 10px;
				}
			}
		}
		
		.loading{
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 5;
			background-color: rgba(0,0,0,0.5);
		}
	}
</style>